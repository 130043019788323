<template>
  <div>
    <button v-if="loading && !response" class="btn btn-success" type="button" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Loading...
    </button>
    <button v-if="!loading || response" v-on:click="(loading = true) && send()" class="btn btn-success" type="button">
      {{ text }}
    </button>

  </div>
</template>

<script>
export default {
  name: "SendButton",
  props: ["response", "send", "text"],
  data() {
    return {
      loading: false
    }
  },
  methods: {
    enable: function () {
      this.loading = false;
    }
  }

}
</script>

<style scoped>

.custom-modal {
  top: 150px
}

</style>
