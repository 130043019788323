<template>
  <div id="contactEdit">
    <app-menu></app-menu>
    <h2 class="pt-lg-4 pb-lg-4 text-center">Kontakt</h2>
    <div class="col-lg-10 offset-lg-1">
      <div class="row">
        <div class="col-lg-6">
          <form>
            <div class="form-group">
              <label for="Name">Jméno:</label>
              <input type="text" class="form-control" id="Name" v-model="contact.name" placeholder="Jméno">
            </div>
            <div class="form-group">
              <label for="e-mail">E-mail:</label>
              <input type="email" class="form-control" id="e-mail" v-model="contact.email" placeholder="E-mail">
            </div>
          </form>
        </div>
        <div class="col-lg-6">
          <form>
            <div class="form-group">
              <label for="telephone">Telefon:</label>
              <input type="text" class="form-control" id="telephone" v-model="contact.phoneNumber"
                     placeholder="Telefon">
            </div>
          </form>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="position_cs">Pozice česky:</label>
              <input type="text" class="form-control" id="position_cs" v-model="contact.role.cs"
                     placeholder="Pozice česky">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="position_en">Pozice anglicky:</label>
              <input type="text" class="form-control" id="position_en" v-model="contact.role.en"
                     placeholder="Pozice anglicky">
            </div>
          </div>
        </div>
      </div>
      <div class="text-center pt-lg-4">
        <send-button
            :send="create"
            text="Uložit Kontakt"
            :response="error"
        />
      </div>
    </div>
    <error-modal :message="error"/>
  </div>
</template>

<script>
import AdminMenu from "@/components/admin/AdminMenu";
import axios from "@/api/axiom-instance";
import ErrorModal from "@/components/admin/ErrorModal";
import SendButton from "@/components/admin/SendButton";

export default {
  name: "programEventEdit",
  components: {
    'app-menu': AdminMenu,
    'error-modal': ErrorModal,
    'send-button': SendButton
  },
  data() {
    return {
      contact: {role: {}},
      error: null
    }
  },
  created() {
    if (this.$route.params.id != null) {
      this.load(this.$route.params.id)
    }
  },
  methods: {
    create: function () {
      axios.post("/contact", this.contact).then(() => {
        this.$router.push({path: "/admin/contact"})
      }).catch(error => this.error = error)
    },
    load: function (id) {
      axios
          .get("/contact", {params: {id: id}})
          .then((success) => {
            this.contact = success.data;
          }).catch(error => this.error = error)
    }
  }
}
</script>

<style scoped>
#contactEdit {
  background-color: white;
  height: 100vh;
}
</style>
