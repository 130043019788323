<template>
  <div class="modal custom-modal" tabindex="-1" v-bind:style="visible ? 'display: block' : ''" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Došlo k chybě:</h5>
          <button type="button" class="close" v-on:click="visible = false" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{ ((message != null && typeof message == "object") && message.response.data.error) ? message.response.data.error : message }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" v-on:click="visible = false">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorModal",
  props: ["message"],
  watch: {
    message: function (newValue) {
      if (newValue) {
        this.visible = true;
      }
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {}

}
</script>

<style scoped>

.custom-modal {
  top: 150px
}

</style>
