<template>
  <div>
    <nav class="navbar navbar-expand-sm pt-lg-4">
      <!-- Brand -->
      <a class="navbar-brand pl-lg-4 text-left" href="/"><img class="img-fluid" width="40%"
                                                              src="@/assets/cropped-logo_2019.png" alt="Logo"></a>
      <!-- Links -->
      <div class="col-lg-8 text-center">
        <div class="dropdown">
          <router-link to="/admin/program">
            <button class="dropbtn">{{ "Admin.menu.program" | staticTranslation }}▾</button>
          </router-link>
          <div class="dropdown-content">
            <a href="/admin/program/">{{ "Admin.menu.year" | staticTranslation }}</a>
            <a href="/admin/program/place">{{ "Admin.menu.place" | staticTranslation }}</a>
            <a href="/admin/program/genre">{{ "Admin.menu.genre" | staticTranslation }}</a>
            <a href="/admin/program/event">{{ "Admin.menu.event" | staticTranslation }}</a>
            <a href="/admin/program/interpret">{{ "Admin.menu.interprets" | staticTranslation }}</a>
            <a href="/admin/program/director">{{ "Admin.menu.directors" | staticTranslation }}</a>
          </div>
        </div>
        <div class="dropdown">
          <router-link to="/admin/news">
            <button class="dropbtn">{{ "Admin.menu.news" | staticTranslation }}</button>
          </router-link>
        </div>
        <div class="dropdown">
          <router-link to="/admin/contact">
            <button class="dropbtn">{{ "Admin.menu.contact" | staticTranslation }}</button>
          </router-link>
        </div>
        <div class="dropdown">
          <router-link to="/admin/album">
            <button class="dropbtn">{{ "Admin.menu.gallery" | staticTranslation }}</button>
          </router-link>
        </div>
        <div class="dropdown">
          <router-link to="/admin/subscribe">
            <button class="dropbtn">{{ "Admin.menu.mail" | staticTranslation }}</button>
          </router-link>
        </div>
        <div class="dropdown pl-lg-4">
          <a v-bind:href="getLogoutUrl()">
            <div class="btn-danger btn-sm">{{ "Admin.menu.logout" | staticTranslation }}</div>
          </a>
        </div>
      </div>
      <div class="col-lg-2">
        <div  type="button" v-on:click="setCs" v-bind:class="{ active: isActiveCs}" class="btn btn-dark btn-sm">CZ
        </div>
        <div type="button" v-on:click="setEn" v-bind:class="{ active: isActiveEn}" class="btn btn-dark btn-sm">EN</div>
      </div>
    </nav>
    <hr>
  </div>
</template>

<script>
import config from "@/config"

export default {
  name: "AdminMenu",
  methods: {
    getLogoutUrl: function () {
      return config.API_URL + "/logout"
    },
    setCs: function () {
      localStorage.setItem("lang", "cs")
      location.reload()
    },
    setEn: function () {
      localStorage.setItem("lang", "en")
      location.reload()
    }
  },
  computed: {
    isActiveCs: function () {
      return !(localStorage.getItem('lang') === 'en')
    },
    isActiveEn: function () {
      return (localStorage.getItem('lang') === 'en')
    }
  }

}
</script>

<style scoped>
nav {
  background-color: white;
}

a:hover {
  text-decoration: none;
}

.dropbtn {
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
  background-color: white;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: white;
}
.btn-dark {
  background-color: #5a6169;
  border-color: #5a6169;
}

.btn-dark:hover {
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-sm {
  margin: 3px;
}
</style>
